import React, { useCallback, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import { removeViewFromHome } from '../../../shared/foreground/actions/homeActions';
import useViewRowData from '../../../shared/foreground/utils/useViewRowData';
import { FilteredView, FirstClassDocument } from '../../../shared/types';
import getEmojiFromStartOfString from '../../../shared/utils/getEmojiFromStartOfString';
import Button from './Button';
import Carousel from './Carousel';
import DocumentCard from './DocumentCard';
import LeftArrow from './icons/ArrowLeftIcon';
import ChevronDown from './icons/ChevronDownIcon';
import Popover from './Popovers/Popover';
import styles from './ViewRow.module.css';

const maxNumberOfDocumentsPerRow = 30;

type Props = {
  itemLinkState?: { [key: string]: unknown; };
  onCarouselPageChange?: (index: number) => void;
  onItemLinkClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, doc: FirstClassDocument, pageIndex: number) => void;
  onQueryEnded?: () => void;
  onQueryError?: () => void;
  view: FilteredView;
};

export default (function ViewRow({
  itemLinkState,
  onItemLinkClick: onItemLinkClickArgument,
  onQueryEnded,
  onQueryError,
  view,
}) {
  const {
    areThereMoreDocumentsNotFetched,
    documents,
  } = useViewRowData({
    maxNumberOfDocumentsPerRow,
    onQueryEnded,
    onQueryError,
    view,
  });

  const currentPageIndexRef = useRef(0);
  const onPageIndexSet = useCallback((newValue: number) => {
    currentPageIndexRef.current = newValue;
  }, []);

  const onItemLinkClick = useCallback((event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, documentData: FirstClassDocument) => {
    if (!onItemLinkClickArgument) {
      return;
    }
    // This assumes that users can't focus and press enter on the link without it being in view (which I tested)
    onItemLinkClickArgument(event, documentData, currentPageIndexRef.current);
  }, [onItemLinkClickArgument]);

  const documentListItems = documents.map((documentData) => <li key={documentData.id}>
    <DocumentCard
      doc={documentData}
      linkProps={{
        // Used when navigating back to home
        id: `${view.id}__${documentData.id}`,
        onClick: (event) => onItemLinkClick(event, documentData),
        to: {
          pathname: `/read/${documentData.id}`,
          state: itemLinkState,
        },
      }}
    />
  </li>);

  const urlOfViewPage = `/filter/${encodeURIComponent(view.query)}`;

  if (areThereMoreDocumentsNotFetched) {
    let emojiElement: JSX.Element | null = null;
    const emoji = getEmojiFromStartOfString(view.name);
    if (emoji) {
      emojiElement = <p className={styles.moreEmoji}>{emoji}</p>;
    }

    documentListItems.push(
      <li
        className={styles.more}
        key="more">
          <Link
            className={styles.moreLink}
            to={urlOfViewPage}>
            {emojiElement}
            <p>There are more items in this filtered view</p>
            <div>
              <Button
                className={styles.moreButton}
                variant="primary">
                  View more
                  <LeftArrow
                    className={styles.moreArrow}
                    text=""
                  />
              </Button>
            </div>
          </Link>
      </li>,
    );
  }

  const carouselControlsContainerId = `home-page-${view.id}-carousel-controls-container`;
  const actionsIconWrapperRef = useRef<HTMLSpanElement>(null);
  const [isActionsPopoverShown, setIsActionsPopoverShown] = useState(false);
  const hideActionsPopoverShown = useCallback(() => setIsActionsPopoverShown(false), []);
  const showActionsPopoverShown = useCallback(() => setIsActionsPopoverShown(true), []);

  const onClickRemoveAction = useCallback(async () => {
    await removeViewFromHome({
      id: view.id,
      userInteraction: 'click',
    });
    hideActionsPopoverShown();
  }, [hideActionsPopoverShown, view.id]);

  let actionsPopover: JSX.Element | null = null;
  if (actionsIconWrapperRef.current) {
    actionsPopover = <Popover
      className={styles.actionsPopover}
      hidePopover={hideActionsPopoverShown}
      isShown={isActionsPopoverShown}
      pointerDownTimeout={250}
      popperOptions={{
        placement: 'bottom-start',
      }}
      reference={actionsIconWrapperRef.current}
      shouldHideOnBlur={false}
      showPopover={showActionsPopoverShown}>
      <ul className={styles.actionsPopoverList}>
        <li className={styles.actionsPopoverListItem}>
          <Button
            className={styles.actionsPopoverAction}
            to={urlOfViewPage}
            variant="unstyled">
              Go to filtered view
          </Button>
        </li>
        <li className={styles.actionsPopoverListItem}>
          <Button
            className={styles.actionsPopoverAction}
            onClick={onClickRemoveAction}
            variant="unstyled">
              Remove from Home
          </Button>
        </li>
      </ul>
    </Popover>;
  }
  if (documents.length === 0) {
    return null;
  }
  return <>
    <div className={styles.rowHeader}>
      <div className={styles.rowHeaderMain}>
        <h2 className={styles.rowName}>
          <Button
            className={styles.rowNameButton}
            onClick={showActionsPopoverShown}
            ref={actionsIconWrapperRef}
            variant="unstyled">
            {view.name}
            <ChevronDown
              className={styles.rowNameActionsIcon}
              text="Actions"
            />
          </Button>
        </h2>
        {actionsPopover}
        <p className={styles.rowDescription}>{view.description}</p>
      </div>
      <div
        className={styles.carouselControlsContainer}
        id={carouselControlsContainerId}
      />
    </div>

    <Carousel
      controlsDestinationId={carouselControlsContainerId}
      listClassName={styles.documents}
      onPageIndexSet={onPageIndexSet}>
      {documentListItems}
    </Carousel>
  </>;
}) as React.FC<Props>;
