import debounce from 'lodash/debounce';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import Button from './Button';
import styles from './CarouselControls.module.css';
import ChevronDown from './icons/ChevronDownIcon';
import SplitButtonGroup from './SplitButtonGroup';

type Props = {
  className?: string;
  listElement: HTMLElement;
  onPageChange?: (pageIndex: number) => void;
  onPageIndexSet?: (pageIndex: number) => void;
};

export default function CarouselControls ({
  className,
  listElement,
  onPageChange,
  onPageIndexSet,
}: Props) {
  const classes = ['carouselControls', styles.carouselControls, className].filter(Boolean);

  // Zero-based
  const [currentIndex, setCurrentIndex] = useState(0);
  const lastKnownCurrentIndexRef = useRef(currentIndex);
  const amountOfTimesCurrentIndexUseEffectHasFiredRef = useRef(0);
  useEffect(() => {
    amountOfTimesCurrentIndexUseEffectHasFiredRef.current++;
    onPageIndexSet?.(currentIndex);

    if (lastKnownCurrentIndexRef.current === currentIndex || currentIndex === 0 && amountOfTimesCurrentIndexUseEffectHasFiredRef.current === 0) {
      return;
    }
    lastKnownCurrentIndexRef.current = currentIndex;
    onPageChange?.(currentIndex);
  }, [currentIndex, onPageChange, onPageIndexSet]);
  const [totalNumberOfPages, setTotalNumberOfPages] = useState(0);

  const [isScrolled, setIsScrolled] = useState(false);
  const canGoBack = useMemo(() => currentIndex > 0 || isScrolled, [currentIndex, isScrolled]);
  const canGoForward = useMemo(() => currentIndex < totalNumberOfPages - 1, [currentIndex, totalNumberOfPages]);

  // Look at the list's width, scroll position, etc. and set the current index, how many pages there are, etc.
  const scrapeCurrentStateFromDom = useCallback(() => {
    if (listElement.scrollWidth <= listElement.clientWidth) {
      setTimeout(() => setTotalNumberOfPages(0));
      setCurrentIndex(0);
      setIsScrolled(false);

      return {
        current: 0,
        total: 0,
      };
    }

    const total = Math.ceil(listElement.scrollWidth / listElement.clientWidth);
    const current = listElement.scrollLeft === 0 ? 0 : Math.round(listElement.scrollLeft / listElement.clientWidth);
    setTimeout(() => setTotalNumberOfPages(total)); // setTimeout() prevents infinite resize observer loops
    setCurrentIndex(current);
    setIsScrolled(Boolean(listElement.scrollLeft));

    return {
      current,
      total,
    };
  }, [listElement]);

  // Set initial state on mount (the current index might not be 0)
  useEffect(() => {
    scrapeCurrentStateFromDom();
  }, [scrapeCurrentStateFromDom]);

  // Whenever the list/window is resized or the list is scrolled, update the state
  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      const { total } = scrapeCurrentStateFromDom();

      const spacer = listElement.lastElementChild as HTMLElement | null;
      if (!spacer) {
        throw new Error("Can't find spacer in list element");
      }

      const spacerWidth = `${listElement.clientWidth * total - listElement.scrollWidth}px`;
      spacer.style.minWidth = spacerWidth;
      spacer.style.width = spacerWidth;
    });
    resizeObserver.observe(listElement);

    const onScroll = debounce(() => {
      scrapeCurrentStateFromDom();
    }, 50);
    listElement.addEventListener('scroll', onScroll, { passive: true });

    return () => {
      resizeObserver?.disconnect();
      listElement.removeEventListener('scroll', onScroll);
    };
  }, [listElement, scrapeCurrentStateFromDom]);

  const move = useCallback((delta: number) => {
    const newIndex = Math.max(0, Math.min(currentIndex + delta, totalNumberOfPages - 1));

    const newScrollLeft = newIndex * listElement.clientWidth;
    if (listElement.scrollLeft === newScrollLeft) {
      return;
    }
    listElement.scrollLeft = newIndex * listElement.clientWidth;
  }, [currentIndex, listElement, totalNumberOfPages]);

  let dotsList: JSX.Element | null = null;
  if (totalNumberOfPages) {
    const dots = new Array(totalNumberOfPages)
      .fill(null)
      .map((_, index) => {
        const dotClasses = [styles.dot];
        if (index === currentIndex) {
          dotClasses.push(styles.dotWhenActive);
        }
        /* eslint-disable react/no-array-index-key */
        return <li
          className={dotClasses.join(' ')}
          key={index}
        />;
        /* eslint-enable react/no-array-index-key */
      });
    dotsList = <ol className={styles.dots}>{dots}</ol>;
  }

  return <div
    aria-hidden
    className={classes.join(' ')}>
    {dotsList}
    <SplitButtonGroup>
      <Button
        disabled={!canGoBack}
        onClick={() => move(-1)}
        tabIndex="-1"
        variant="default">
          <ChevronDown
            className={styles.previousButtonIcon}
            text="Previous"
          />
      </Button>
      <Button
        disabled={!canGoForward}
        onClick={() => move(+1)}
        tabIndex="-1"
        variant="default">
        <ChevronDown
          className={styles.nextButtonIcon}
          text="Next"
        />
      </Button>
    </SplitButtonGroup>
  </div>;
}
