import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useFindOne } from '../../../shared/foreground/databaseHooks';
import DelayMount from '../../../shared/foreground/DelayMount';
import { globalState } from '../../../shared/foreground/models';
import { useFilteredViewsInHome } from '../../../shared/foreground/stateHooks/home';
import { FilteredView, FirstClassDocument } from '../../../shared/types';
import type { LenientWindow } from '../../../shared/types/LenientWindow';
import useUserScrollable from '../hooks/useUserScrollable';
import Button from './Button';
import styles from './HomePage.module.css';
import ChevronDown from './icons/ChevronDownIcon';
import HomeIcon from './icons/HomeIcon';
import MainContentHeader from './MainContentHeader';
import ConfigureHomePopover from './Popovers/ConfigureHomePopover';
import Spinner from './Spinner';
import StandardPage from './StandardPage';
import { TrialExpiryNotice } from './TrialExpiryNotice';
import ViewRow from './ViewRow';

type LastClickedHomeItemDescription = {
  docId: FirstClassDocument['id'];
  viewId: FilteredView['id'];
};

declare let window: LenientWindow & {
  lastClickedHomeItemDescription?: LastClickedHomeItemDescription;
};

export default function HomePage(): JSX.Element {
  const history = useHistory<{ didNavigateUsingBackShortcut?: boolean; didNavigateUsingUIBackButton?: boolean; } | void>();
  const initialLocationRef = useRef(history.location);

  const [isPageScrolled, setIsPageScrolled] = useState(false);
  const scrollableRootRef = useRef(null);
  useUserScrollable(scrollableRootRef.current, (scrollTop) => setIsPageScrolled(scrollTop > 0));

  const firstName = globalState(useCallback((state) => state.client.profile?.first_name, []));

  const [firstDocument] = useFindOne('documents');
  const welcomeMessage = [
    'Welcome',
    firstDocument ? 'back' : null,
    firstName,
  ]
    .filter(Boolean)
    .join(' ');

  const configureButtonRef = useRef<HTMLButtonElement>();
  useEffect(() => configureButtonRef.current?.focus(), []);
  const [isConfigureHomePopoverShown, setIsConfigureHomePopoverShown] = useState(false);
  const hideConfigureHomePopover = useCallback(() => setIsConfigureHomePopoverShown(false), []);
  const showConfigureHomePopover = useCallback(() => setIsConfigureHomePopoverShown(true), []);
  let configureHomePopover: JSX.Element | null = null;
  if (configureButtonRef.current) {
    configureHomePopover = <ConfigureHomePopover
      hidePopover={hideConfigureHomePopover}
      isShown={isConfigureHomePopoverShown}
      reference={configureButtonRef.current}
      showPopover={showConfigureHomePopover}
    />;
  }

  const views = useFilteredViewsInHome();

  /*
    This page could easily be slow. Each row could potentially run a expensive query against each document.

    The easiest and highest impact performance strategy is to stagger the rendering of these rows, which spreads out
    this computation over time. We render one row. When the query had ended, or errored, we render the next row.
    `maxIndexAllowedToRender` controls the rendering.
  */
  const [maxIndexAllowedToRender, setMaxIndexAllowedToRender] = useState(0);
  const renderNextItem = useCallback((currentItemIndex: number) => {
    // We use `Math.max` because we don't want to revert the index back if there are any misfires / race condition bugs
    setMaxIndexAllowedToRender((prev) => Math.max(prev, currentItemIndex + 1));
  }, []);


  /*
    Handle coming back to home after clicking a document. The hash covers when the browser back button was pressed.
    window.lastClickedHomeItemDescription handles in-app back button press.
  */
  const hasScrolledToLastClickedItemRef = useRef(false);
  useEffect(() => {
    if (hasScrolledToLastClickedItemRef.current) {
      return;
    }

    let description: LastClickedHomeItemDescription | undefined;
    if ((initialLocationRef.current.state?.didNavigateUsingUIBackButton || initialLocationRef.current.state?.didNavigateUsingBackShortcut) && 'lastClickedHomeItemDescription' in window) {
      description = window.lastClickedHomeItemDescription;
    } else if (initialLocationRef.current.hash && /^[\da-z]+__[\da-z]+$/i.test(initialLocationRef.current.hash)) {
      const [viewId, docId] = initialLocationRef.current.hash.split('__');
      description = { docId, viewId };
    }

    if (!description) {
      delete window.lastClickedHomeItemDescription;
      return;
    }

    const element = document.getElementById(`${description.viewId}__${description.docId}`);
    if (!element) {
      return;
    }
    element.scrollIntoView();
    delete window.lastClickedHomeItemDescription;
    hasScrolledToLastClickedItemRef.current = true;
  }, [maxIndexAllowedToRender]); // On load and whenever a row is rendered

  let mainContent: JSX.Element | null = null;
  if (views.length) {
    const rows = views.map((view, index) =>
      <li
        className={styles.row}
        key={view.id}>
        {index <= maxIndexAllowedToRender
          ? <>
              <ViewRow
                itemLinkState={{
                  documentPathPrefix: '',
                  parentPath: '/home',
                  filteredView: view,
                }}
                onItemLinkClick={(event, doc) => {
                  // Matches ViewRow item id
                  history.replace({ hash: `${view.id}__${doc.id}` });
                  window.lastClickedHomeItemDescription = {
                    docId: doc.id,
                    viewId: view.id,
                  } as LastClickedHomeItemDescription;
                }}
                onQueryEnded={() => renderNextItem(index)}
                onQueryError={() => renderNextItem(index)}
                view={view}
              />
              <DelayMount
                amount={500}
                onMount={() => renderNextItem(index)}
              />
            </>
          : <Spinner className={styles.rowLoadingIndicator} />}
    </li>);
    mainContent = <ol
      className={`${styles.rows} has-visible-scrollbar`}
      ref={scrollableRootRef}>
        {rows}
    </ol>;
  } else {
    mainContent = <div
      className={styles.emptyMainContent}
      ref={scrollableRootRef}>
      <HomeIcon
        className={styles.emptyMainContentIcon}
        text=""
      />
      <p className={styles.emptyMainContentMainText}>Selected filtered views will appear here</p>
      <p>Use the configure menu to add filtered views to Home</p>
    </div>;
  }

  const mainClasses = [styles.home];
  if (isConfigureHomePopoverShown) {
    mainClasses.push(styles.homeWhenConfigureHomePopoverShown);
  }

  return <StandardPage>
    <main
      className={mainClasses.join(' ')}>
      <MainContentHeader
        className={styles.pageHeader}
        isPageScrolled={isPageScrolled}>
        <h1 className="hideAccessibly">Home</h1>
        <p className={styles.welcomeMessage}>{welcomeMessage}</p>
        <TrialExpiryNotice />
        <Button
          className={styles.configureButton}
          onClick={showConfigureHomePopover}
          ref={configureButtonRef}
          variant="secondary">
          Configure
          <ChevronDown
            className={styles.configureButtonIcon}
            text=""
          />
        </Button>
      </MainContentHeader>

      {mainContent}
      {configureHomePopover}
    </main>
  </StandardPage>;
}
