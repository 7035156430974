import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';

import { addViewToHome, removeViewFromHome, reorderViewInHome } from '../../../../shared/foreground/actions/homeActions';
import { openFiltersSubMenu } from '../../../../shared/foreground/cmdPalette';
import { useFilteredViewsArray } from '../../../../shared/foreground/stateHooks/filteredViews';
import forwardRef from '../../../../shared/foreground/utils/forwardRef';
import useGlobalStateWithFallback from '../../../../shared/foreground/utils/useGlobalStateWithFallback';
import useLiveValueRef from '../../../../shared/foreground/utils/useLiveValueRef';
import useStatePlusLiveValueRef from '../../../../shared/foreground/utils/useStatePlusLiveValueRef';
import { FilteredView } from '../../../../shared/types';
import reorder from '../../../../shared/utils/reorder';
import Button from '../Button';
import Checkbox from '../Checkbox';
import StrokeGrabIcon from '../icons/StrokeGrabIcon';
import ViewsIcon from '../icons/ViewsIcon';
import styles from './ConfigureHomePopover.module.css';
import Popover, { Props as PopoverProps } from './Popover';

const sortViews = (views: FilteredView[], viewsInHome: FilteredView['id'][]) => {
  return Array.from(views).sort((viewA, viewB) => {
    const indexOfViewAInHome = viewsInHome.indexOf(viewA.id);
    const isViewAInHome = indexOfViewAInHome !== -1;
    const indexOfViewBInHome = viewsInHome.indexOf(viewB.id);
    const isViewBInHome = indexOfViewBInHome !== -1;
    if (isViewAInHome !== isViewBInHome) {
      return isViewAInHome ? -1 : 1;
    }
    if (isViewAInHome) {
      return indexOfViewAInHome < indexOfViewBInHome ? -1 : 1;
    }
    return viewA.name.localeCompare(viewB.name);
  });
};

const getIndexInViewsInHome = ({
  id,
  viewIdsInHome,
  views,
}: {
  id: FilteredView['id'];
  viewIdsInHome: FilteredView['id'][];
  views: FilteredView[];
}) =>
  views
    .filter((view) => view.id === id || viewIdsInHome.includes(view.id))
    .findIndex((view) => view.id === id);

type ItemProps = {
  extraProps?: { [key: string]: unknown; };
  isBeingDragged?: boolean;
  onCheckboxChange?: (isChecked: boolean, view: FilteredView) => void;
  view: FilteredView;
  viewIdsInHome: FilteredView['id'][];
};

const Item = forwardRef<ItemProps, HTMLLIElement>(function Item({
  isBeingDragged,
  onCheckboxChange,
  view,
  viewIdsInHome,
  ...extraProps
}, ref) {
  const classes = [styles.listItem];
  if (isBeingDragged) {
    classes.push(styles.listItemBeingDragged);
  }
  const labelId = `configure-home-checkbox-label-${view.id}`;
  return <li
    {...extraProps}
    className={classes.join(' ')}
    ref={ref}
    tabIndex={-1}>
      <label
        className={styles.label}
        id={labelId}>
        <Checkbox
          className={styles.checkbox}
          isChecked={viewIdsInHome.includes(view.id)}
          labelId={labelId}
          onCheckedChange={(state) => onCheckboxChange?.(Boolean(state), view)}
          tabIndex={-1}
        />
        <span className={styles.name}>{view.name}</span>
    </label>
    <span
      className={styles.dragHandle}
    >
      <StrokeGrabIcon text="Reorder" />
    </span>
  </li>;
});

type Props = PopoverProps;

export default function ConfigureHomePopover (props: Props): JSX.Element {
  const propsRef = useLiveValueRef(props);

  const views = useFilteredViewsArray();
  const viewsRef = useLiveValueRef(views);
  const viewIdsInHome = useGlobalStateWithFallback([], useCallback((state) => state.persistent.home?.views, []));
  const viewIdsInHomeRef = useLiveValueRef(viewIdsInHome);

  const [sortedViews, setSortedViews, sortedViewsRef] = useStatePlusLiveValueRef<typeof views>([]);
  const viewsIdString = useMemo(() => views.map(({ id }) => id).sort((a, b) => a.localeCompare(b)).join('-'), [views]);

  // When the views change, reorder them in popover only if it's closed
  useEffect(() => {
    if (propsRef.current.isShown) {
      return;
    }
    setSortedViews(sortViews(viewsRef.current, viewIdsInHomeRef.current));
  }, [propsRef, setSortedViews, viewIdsInHome, viewsIdString, viewIdsInHomeRef, viewsRef]);

  const hasAViewBeenAddedOrRemoveWhileShownRef = useRef(false);
  const hasReorderHappenedWhileShownRef = useRef(false);
  // When the popover gets closed, reorder if a checkbox was toggled / a reorder happened while it was open
  useEffect(() => {
    if (props.isShown || !hasAViewBeenAddedOrRemoveWhileShownRef.current && !hasReorderHappenedWhileShownRef.current) {
      return;
    }
    setSortedViews(sortViews(viewsRef.current, viewIdsInHomeRef.current));
  }, [hasAViewBeenAddedOrRemoveWhileShownRef, hasReorderHappenedWhileShownRef, props.isShown, setSortedViews, viewIdsInHomeRef, viewsRef]);

  const onDragEnd = useCallback(async (result: DropResult) => {
    if (!result.destination || result.source.index === result.destination.index) {
      return;
    }

    const viewId = sortedViews[result.source.index].id;
    const hasOrderOfViewsInHomeChanged = viewIdsInHomeRef.current.includes(viewId) && sortedViews
      .slice(Math.min(result.source.index, result.destination.index), Math.max(result.source.index, result.destination.index) + 1)
      .some((view) => view.id !== viewId && viewIdsInHomeRef.current.includes(view.id));

    const newSortedViews = reorder(sortedViews, result.source.index, result.destination.index);
    setSortedViews(newSortedViews);
    hasReorderHappenedWhileShownRef.current = true;

    if (hasOrderOfViewsInHomeChanged) {
      const newIndex = getIndexInViewsInHome({
        id: viewId,
        views: newSortedViews,
        viewIdsInHome: viewIdsInHomeRef.current,
      });
      if (newIndex === -1) {
        throw new Error('newIndex is -1');
      }
      await reorderViewInHome({
        id: viewId,
        newIndex,
      }, { userInteraction: 'drag' });
    }
  }, [setSortedViews, sortedViews, viewIdsInHomeRef]);

  const onCheckboxChange = useCallback(async (isChecked: boolean, view: FilteredView) => {
    hasAViewBeenAddedOrRemoveWhileShownRef.current = true;
    if (isChecked) {
      const index = getIndexInViewsInHome({
        id: view.id,
        views: sortedViewsRef.current,
        viewIdsInHome: viewIdsInHomeRef.current,
      });
      if (index === -1) {
        throw new Error('newIndex is -1');
      }

      await addViewToHome({
        id: view.id,
        index,
      }, { userInteraction: 'unknown' });
      return;
    }
    await removeViewFromHome({ id: view.id, userInteraction: 'unknown' });
  }, [sortedViewsRef, viewIdsInHomeRef]);


  const items = sortedViews.map((view, index) => {
    return <Draggable
      draggableId={view.id}
      index={index}
      key={view.id}>
      {(provided, snapshot) => <Item
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        onCheckboxChange={onCheckboxChange}
        ref={provided.innerRef}
        view={view}
        viewIdsInHome={viewIdsInHome}
      />}
    </Draggable>;
  });

  const popoverClasses = [styles.configureHomePopover];

  const onClickCreateView = useCallback(async () => {
    await openFiltersSubMenu();
    props.hidePopover();
  }, [props]);

  let content: JSX.Element | null = null;
  if (items.length) {
    content = <>
      <p className={styles.popoverTitle}>Display on Home</p>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable
          droppableId="configure-home-droppable"
          renderClone={(provided, snapshot, rubric) => <Item
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            isBeingDragged
            view={sortedViews[rubric.source.index]}
            viewIdsInHome={viewIdsInHome}
            ref={provided.innerRef} />}>
          {(provided) => <ol
            className={styles.list}
            ref={provided.innerRef}
            {...provided.droppableProps}>
            {items}
            {provided.placeholder}
          </ol>}
        </Droppable>
      </DragDropContext>
    </>;
  } else {
    popoverClasses.push(styles.configureHomePopoverWhenEmpty);
    content = <>
      <ViewsIcon
        className={styles.emptyStateIcon}
        text="" />
      <p>To configure your Home screen, first create a filtered view</p>
      <Button
        className={styles.createViewButton}
        onClick={onClickCreateView}
        variant="primary">
        Create filtered view
      </Button>
    </>;
  }

  return <Popover
    className={popoverClasses.join(' ')}
    popperOptions={{
      placement: 'bottom-end',
    }}
    shouldHideOnBlur={false}
    {...props}>
    {content}
  </Popover>;
}
