import { useCallback, useMemo } from 'react';

import { notEmpty } from '../../typeValidators';
import useGlobalStateWithFallback from '../utils/useGlobalStateWithFallback';
import { useFilteredViews, useFilteredViewsArray } from './filteredViews';

export function useFilteredViewsInHome() {
  const homeViewIds = useGlobalStateWithFallback([], useCallback((state) => state.persistent.home?.views, []));
  const allViews = useFilteredViews();
  return useMemo(() => homeViewIds.map((id) => allViews[id]).filter(notEmpty), [allViews, homeViewIds]);
}

export function useFilteredViewsNotInHome() {
  const homeViewIds = useGlobalStateWithFallback([], useCallback((state) => state.persistent.home?.views, []));
  const allViews = useFilteredViewsArray();
  return useMemo(() => allViews.filter((view) => !homeViewIds.includes(view.id)), [allViews, homeViewIds]);
}
